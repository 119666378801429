import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const CanISendAttachmentsViaDm = ({ location }) => {
  const title = "インスタントウィンの結果通知に画像を添付できる？"
  const list = [
    { title: "よくある質問", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="Twitterインスタントウィンキャンペーンで、景品の一部として画像や動画、エクセルなどのファイルを添付して配布することができます。(一部カスタマイズが必要です)"
          pagepath={location.pathname}
        />
        <Breadcrumb title="よくある質問" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              当選結果通知のダイレクトメッセージで添付ファイルを送ることはできますか？
            </h1>
            <p className="qa-page__answer">
              PARKLoTのシステムから自動で送付されるダイレクトメッセージに添付ファイルを指定することはできません。
            </p>
            <p className="qa-page__text">
              画像や動画などの添付ファイルをPARKLoTの機能を使って送る場合はカスタマイズが必要です。
            </p>
            <p className="qa-page__text">
              参加者に送りたいデータや資料がある場合は、Google
              Driveなどのストレージサービスのご利用をおすすめします。
            </p>
            <p className="qa-page__text">
              共有可能なリンクを取得し、そのリンクをメッセージとして送付するようにしてください。
            </p>
            <div>
              <h3 className="qa-page__h3">
                参加者へ送付するダイレクトメッセージの文言をカスタマイズすることはできますか？
              </h3>
              <p className="qa-page__text">
                はい、キャンペーン参加者へ送付する結果通知ダイレクトメッセージの文言は管理画面から変更することが可能です。
              </p>
              <p className="qa-page__text">
                ダイレクトメッセージでURLリンクを送付することもできます。
              </p>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default CanISendAttachmentsViaDm
